import React from 'react';
import Helmet from 'react-helmet';
import '../styles/main.scss';

const Layout = ({ children }) => {
	return (
		<div className="Layout">
			<Helmet>
				<html lang="en" />
				<title>100 Reasons Y - Barrie YMCA</title>
				<meta
					name="description"
					content="With our partners, we are building a new kind of Y for Barrie."
				/>
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/apple-touch-icon.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href="/favicon-32x32.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href="/favicon-16x16.png"
				/>
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
				<meta name="msapplication-TileColor" content="#2d89ef" />
				<meta name="theme-color" content="#ffffff" />
			</Helmet>
			{children}
		</div>
	);
};

export default Layout;
